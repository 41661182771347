export interface ILastUpdated {
  updatedAt?: string
  userFullname?: string
}

export enum ColumnType {
  TIME = 'time',
  DATE = 'date',
  DATETIME = 'datetime',
  NUMBER = 'number',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  LAST_UPDATED = 'last_updated',
  SELECT_STATUS = 'select_status'
}

export interface IColumnOption {
  label: string
  value: string
}

export interface ITableColumnType {
  field: string
  title: string
  isEditable: boolean
  type: ColumnType
  extra: {
    options: IColumnOption[]
  }
}

export interface ITableItem {
  id: number
  name: string
  result: Record<string, unknown>
}

export enum ResponseStatuses {
  Forbidden = 403,
  NotAuthorized = 401
}
