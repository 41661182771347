export enum CPChatType {
  Default = 'Default',
  Notes = 'Notes'
}

export interface ICPChatProject {
  id: number
  name: string
}

export interface ICPChatPropTypes {
  projectList: ICPChatProject[]
  hasNotes?: boolean
  loadingNav?: boolean
  disabledNav?: boolean
  showNewMessageNav?: boolean
  enableProjectButton?: boolean
  disabled?: boolean
}
