export enum ECP_INVOICES_TABLE_KEYS {
  Document_name = 'Name des Dokuments',
  Price_value = 'Preiswert',
  Type = 'Typ',
  Service = 'Service',
  CreatedAt = 'Erstellt am',
  SentAt = 'Versendet am',
  Status = 'Status',
  Actions = 'Aktionen',
  ExpiredDeadline = 'rowBg', // save value as rowBg !!!
}

export type TCPInvoiceStatus = 'Paid' | 'Unpaid' | 'Irrelevant' | 'Canceled'

export interface ICPInvoicesTableItemTypes {
  [ECP_INVOICES_TABLE_KEYS.Price_value]?: string
  [ECP_INVOICES_TABLE_KEYS.Type]: string
  [ECP_INVOICES_TABLE_KEYS.Service]: string
  [ECP_INVOICES_TABLE_KEYS.Status]: {
    value: TCPInvoiceStatus
    model: boolean
  } | TCPInvoiceStatus
  [ECP_INVOICES_TABLE_KEYS.CreatedAt]: string[] | string
  [ECP_INVOICES_TABLE_KEYS.SentAt]: string[] | string
  [ECP_INVOICES_TABLE_KEYS.Actions]?: {
    projectId: number
    invoiceId: number
  }
  [ECP_INVOICES_TABLE_KEYS.ExpiredDeadline]?: boolean
}

export type ICPInvoice = {
  [ECP_INVOICES_TABLE_KEYS.Price_value]: any
  [ECP_INVOICES_TABLE_KEYS.Type]: string
  [ECP_INVOICES_TABLE_KEYS.Service]: any
  [ECP_INVOICES_TABLE_KEYS.CreatedAt]: string
  [ECP_INVOICES_TABLE_KEYS.Status]: TCPInvoiceStatus | { value: TCPInvoiceStatus; model: boolean }
  [ECP_INVOICES_TABLE_KEYS.ExpiredDeadline]: boolean
  [ECP_INVOICES_TABLE_KEYS.Actions]?: any // Adding Actions as an optional property
}

export interface ICPInvoicesTableEmitTypes {
  status: {
    id: ICPInvoicesTableItemTypes[ECP_INVOICES_TABLE_KEYS.Actions]
    value: ECP_INVOICES_TABLE_KEYS
  }
  download: {
    ids: ICPInvoicesTableItemTypes[ECP_INVOICES_TABLE_KEYS.Actions]
  }
  statusClick: ICPInvoicesTableItemTypes[ECP_INVOICES_TABLE_KEYS.Actions]
  invoiceClick: {
    ids: ICPInvoicesTableItemTypes[ECP_INVOICES_TABLE_KEYS.Actions]
  }
  cancelInvoice: {
    ids: ICPInvoicesTableItemTypes[ECP_INVOICES_TABLE_KEYS.Actions]
  }
}

export interface ICPInvoicesTablePropTypes {
  data: ICPInvoicesTableItemTypes[]
  ignoreKeys?: ECP_INVOICES_TABLE_KEYS[]
  loading?: boolean
  noDataText?: string
  disabled?: boolean
}
