import { defineStore } from 'pinia'
import type { components } from 'schema.ts'

import { useDateFormat } from '@vueuse/core'

import type {
	IFetchApiAddTimeframe,
	IFetchApiProps
} from '~/portal/api'
import {
	FETCH_API_METHODS,
	fetchApi,
	fetchApiAddTimeframe,
	fetchApiSetSubPhase
} from '~/portal/api'
import { CP_REVIEW_CHECKLIST_TABLE_COLUMNS } from '~/portal/components/pages'
import type {
	ICPActivateProjectPayload,
	ICPSetSubPhaseProgressPayload,
	ICPSingleProjectStoreTypes,
	ICPUpdateProjectSettingsPayload
} from '~/portal/stores'
import { STATE_STORE_KEYS } from '~/portal/types'

export const useSingleProjectStore = defineStore('singleProjectStore', {
	state: (): ICPSingleProjectStoreTypes => ({
		isSidebarCollapsed: false,
		reviewChecklists: [],
		project: {},
		billingAddressList: []
	}),
	getters: {
		currentPhase (state): components['schemas']['GetProjectPhase'] | undefined {
			return state.project.all_phases?.find((phase: any) => phase.is_current)
		},
		isProjectBlocked (state: ICPSingleProjectStoreTypes) {
			return state.project.is_blocked || state.project.is_archived
		}
	},
	actions: {
		async fetchReviewChecklists (token: IFetchApiProps['token']) {
			const response = await fetchApi({
				token,
				url: `/project/${this.project.id}/checklist/list`,
				method: FETCH_API_METHODS.Get,
				pendingKey: STATE_STORE_KEYS.ReviewChecklists
			})

			if (response?.data?.results) {
				this.reviewChecklists = response.data.results.map((projectChecklist: components['schemas']['ProjectChecklist']) => ({
					[CP_REVIEW_CHECKLIST_TABLE_COLUMNS.ReviewChecklistsName]: projectChecklist.checklist.name,
					[CP_REVIEW_CHECKLIST_TABLE_COLUMNS.Date]: useDateFormat(
						projectChecklist.updated_at,
						'DD.MM.YYYY HH:mm'
					)
				}))
			}
		},
		async fetchProjectData (
			{
				token,
				projectId
			}:
				{
					token: IFetchApiProps['token']
					projectId: number
				}
		) {
			const response = await fetchApi({
				token,
				url: `/project/${projectId}`,
				method: FETCH_API_METHODS.Get,
				pendingKey: STATE_STORE_KEYS.UpdateProject
			})

			if (response?.data) {
				this.project = response.data
				return response.data
			}
		},
		async activateProject (
			token: IFetchApiProps['token'],
			payload: ICPActivateProjectPayload
		) {
			if (!this.project.id || !token) return

			await fetchApi({
				token,
				url: `/project/${this.project.id}/activate`,
				method: FETCH_API_METHODS.Post,
				pendingKey: STATE_STORE_KEYS.UpdateProject,
				payload
			})

			await this.fetchProjectData({ token, projectId: this.project.id })
		},
		async setPhaseProgress (
			token: IFetchApiProps['token'],
			payload: ICPSetSubPhaseProgressPayload
		) {
			if (!this.project.id) return
			await fetchApiSetSubPhase({
				token,
				projectId: this.project.id,
				phaseId: payload.phaseId,
				subPhaseId: payload.subPhaseId,
				progress: payload.progress
			})

			await this.fetchProjectData({ token, projectId: this.project.id })
		},
		assignUsersToProject (
			token: IFetchApiProps['token'],
			paylaod:
			ICPUpdateProjectSettingsPayload['assignees']
		) {
			return fetchApi({
				method: FETCH_API_METHODS.Post,
				token,
				url: `/project/${this.project.id}/assign?rewrite=true`,
				payload: paylaod,
				pendingKey: STATE_STORE_KEYS.AssineUsers
			})
		},
		activateTimeframe (
			token: IFetchApiProps['token'],
			timeframe: ICPUpdateProjectSettingsPayload['timeframe']
		) {
			return fetchApi({
				method: FETCH_API_METHODS.Post,
				token,
				url: `/project/${this.project.id}/timeframe/${timeframe?.id}/activate`,
				pendingKey: STATE_STORE_KEYS.ActivateTimeframe,
				payload: { weeks: timeframe?.weeks }
			})
		},
		blockUnblockProject (
			token: IFetchApiProps['token'],
			blockProject: boolean
		) {
			const action = blockProject ? 'block' : 'unblock'
			if (this.project.is_blocked === blockProject) return
			return fetchApi({
				method: FETCH_API_METHODS.Get,
				token,
				url: `/project/${this.project.id}/${action}`,
				pendingKey: STATE_STORE_KEYS.BlockProject
			})
		},
		archiveUnarchiveProject (
			token: IFetchApiProps['token'],
			archiveProject: boolean
		) {
			const action = archiveProject ? 'archive' : 'unarchive'
			if (this.project.is_archived === archiveProject) return
			return fetchApi({
				method: FETCH_API_METHODS.Get,
				token,
				url: `/project/${this.project.id}/${action}`,
				pendingKey: STATE_STORE_KEYS.ArchiveProject
			})
		},
		async updateProject (
			token: IFetchApiProps['token'],
			payload: ICPSingleProjectStoreTypes['project']
		) {
			await fetchApi({
				method: FETCH_API_METHODS.Patch,
				token,
				url: `/project/${this.project.id}/update`,
				pendingKey: STATE_STORE_KEYS.UpdateProject,
				payload
			})

			this.project = {
				...this.project,
				...payload
			}
		},
		async updateProjectSettings (token: IFetchApiProps['token'], payload: ICPUpdateProjectSettingsPayload) {
			if (!this.project.id || !token) return

			const requests: any[] = []
			if (payload.archiveProject !== this.project.is_archived) {
				requests.push(
					this.archiveUnarchiveProject(token, payload.archiveProject)
				)
			}
			if (payload.blockProject !== this.project.is_blocked) {
				requests.push(
					this.blockUnblockProject(token, payload.blockProject)
				)
			}
			if (payload.assignees) {
				requests.push(
					this.assignUsersToProject(token, payload.assignees)
				)
			}
			if (payload.timeframe) {
				requests.push(this.activateTimeframe(token, payload.timeframe))
			}
			if (payload.project) {
				requests.push(this.updateProject(token, payload.project))
			}

			if (!requests.length) return

			await Promise.all(requests)
			await this.fetchProjectData({ token, projectId: this.project.id })
		},
		async addTimeFrame (
			payload: IFetchApiAddTimeframe
		) {
			const response = await fetchApiAddTimeframe(payload)

			if (response?.data?.id) {
				this.project.timeframes.push({
					...payload.payload,
					id: response.data.id,
					is_additional: true,
					order: this.project.timeframes.filter(
						(timeframe: any) => timeframe.type === payload.payload.type
					).length + 1
				})
			}
		},
		async fetchBillingAddressList (token: IFetchApiProps['token']) {
			if (!token) return

			const response = await fetchApi({
				token,
				url: '/project/billing-address/list',
				method: FETCH_API_METHODS.Get,
				pendingKey: STATE_STORE_KEYS.BillingAddress,
				params: {
					return_all: true
				}
			})

			if (response?.data) {
				this.billingAddressList = response.data
			}
		},
		async createBillingAddress ({ token, payload }: {
      token: IFetchApiProps['token']
      payload: components['schemas']['CreateBillingAddress']
    }) {
			if (!token) return

			const response = await fetchApi({
				token,
				url: '/project/billing-address',
				method: FETCH_API_METHODS.Post,
				pendingKey: STATE_STORE_KEYS.BillingAddress,
				payload
			})

			if (response?.data?.id) {
				await this.assignBillingAddress(token, response.data.id)
			}
		},
		async assignBillingAddress (
			token: IFetchApiProps['token'],
			id: number
		) {
			if (!token) return
			if (!this.project.id) return

			await fetchApi({
				token,
				url: `/project/${this.project.id}/billing-address/${id}/assign`,
				method: FETCH_API_METHODS.Get,
				pendingKey: STATE_STORE_KEYS.BillingAddress
			})

			await this.fetchProjectData({ token, projectId: this.project.id })
		},
		async fetchAddBillingAddress (
			token: IFetchApiProps['token'],
			payload: components['schemas']['CreateBillingAddress']
		) {
			if (!token) {
				return
			}

			await fetchApi({
				url: '/project/billing-address',
				method: FETCH_API_METHODS.Post,
				pendingKey: STATE_STORE_KEYS.BillingAddress,
				token,
				payload
			})
		},
		async fetchPatchBillingAddress ({
			payload,
			token,
			id
		}: {
			token: IFetchApiProps['token']
			payload: components['schemas']['PatchedUpdateBillingAddress']
			id: components['schemas']['GetBillingAddress']['id']
		}) {
			if (!token) {
				return
			}

			await fetchApi({
				url: `/project/billing-address/${id}/update`,
				method: FETCH_API_METHODS.Patch,
				pendingKey: STATE_STORE_KEYS.BillingAddress,
				token,
				payload
			})
		},
		async fetchDeleteBillingAddress ({
			id,
			token
		}: {
			token: IFetchApiProps['token']
			id: components['schemas']['GetBillingAddress']['id']
		}) {
			if (!token) {
				return
			}

			await fetchApi({
				url: `/project/billing-address/${id}/delete`,
				method: FETCH_API_METHODS.Delete,
				pendingKey: STATE_STORE_KEYS.BillingAddress,
				token
			})
		}
	}
})
