import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import type { ECP_QUESTION_STATUS } from '~/portal/components'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiGetChecklist {
  token: IFetchApiProps['token']
  projectId: number
}
export interface IFetchApiHideChecklistItem {
  token: IFetchApiProps['token']
  projectId: number
  checklistId: number
  checklistItemId: number
}

export interface IFetchApiSendForInspection {
  token: IFetchApiProps['token']
  projectId: number
  checklistId: number
  checklistItemId: number
  isCustom: boolean
}

export interface IFetchSetQuestionStatus {
  token: IFetchApiProps['token']
  projectId: number
  id: number
  status: ECP_QUESTION_STATUS
  isCustom: boolean
}

export interface IFetchApiDownloadChecklistItem {
  token: IFetchApiProps['token']
  projectId: number
  checklistId: number
  checklistItemId: number
  isCustom: boolean
}

export interface IFetchApiSetPreDefinedComment {
  token: IFetchApiProps['token']
  projectId: number
  questionId: number
  isCustom: boolean
  payload: {
    selected_comments: number[]
    own_comment: { value: string } | null
  }
}

export const fetchApiGetCurrentChecklist = async (
	payload: IFetchApiGetChecklist
): Promise<components['schemas']['GetCurrentChecklist'] | undefined> => {
	if (!payload.token && !payload.projectId) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${payload.projectId}/current-checklist`,
		pendingKey: STATE_STORE_KEYS.CurrentChecklist,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetInternalChecklist = async (
	payload: IFetchApiGetChecklist
) => {
	if (!payload.token && !payload.projectId) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${payload.projectId}/internal-checklist`,
		pendingKey: STATE_STORE_KEYS.CurrentChecklist,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiDeleteChecklistItem = (payload: {
  token: IFetchApiProps['token']
  checklistId: number
  checklistItemId: number
  isCustom?: boolean
}) => {
	if (!payload.token || !payload.checklistId || !payload.checklistItemId) return

	const itemType = payload.isCustom ? 'custom-item' : 'item'
	const url = `/project/checklist/${payload.checklistId}/${itemType}/${payload.checklistItemId}/delete`

	return fetchApi({
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.DeleteChecklistItem,
		token: payload.token,
		url
	})
}

export const createDocumentsChecklistItem = async (payload: {
  token: IFetchApiProps['token']
  checklistId: number
  projectId: number
  isCustom?: boolean
  data: components['schemas']['CreateChecklistItem']
}) => {
	if (!payload.token && !payload.checklistId) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.AddChecklistItem,
		token: payload.token,
		url: `/project/${payload.projectId}/checklist/${payload.checklistId}/add-item`,
		payload: payload.data
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiPostAnswers = async (payload: {
  token: IFetchApiProps['token']
  projectId: number
  checklistId: number
  checklistItemId: number
  isCustom?: boolean
  data: any
}) => {
	if (!payload.token) return

	const itemType = payload.isCustom ? 'custom-item' : 'item'

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${payload.projectId}/checklist/${payload.checklistId}/${itemType}/${payload.checklistItemId}/answer`,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.PostAnswers,
		payload: {
			questions: payload.data
		}
	})
}

export const fetchApiSetStatus = ({
	token,
	projectId,
	id,
	status,
	isCustom
}: IFetchSetQuestionStatus) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		method: FETCH_API_METHODS.Put,
		url: `/project/${projectId}/checklist/${itemType}/question/${id}/set-status`,
		token,
		pendingKey: STATE_STORE_KEYS.SetQuestionStatus,
		forceFetch: true,
		payload: {
			status
		}
	})
}

export const fetchApiAddComment = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  id: number
  comment: string
  isCustom: boolean
}) => {
	const itemType = payload.isCustom ? 'custom-item' : 'item'
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${payload.projectId}/checklist/${itemType}/question/${payload.id}/add-comment`,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.AddQuestionComment,
		forceFetch: true,
		payload: {
			value: payload.comment
		}
	})
}

export const fetchApiHideChecklistItem = ({
	token,
	projectId,
	checklistId,
	checklistItemId
}: IFetchApiHideChecklistItem) => {
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/checklist/${checklistId}/item/${checklistItemId}/hide`,
		token,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive
	})
}

export const fetchApiUnhideChecklistItem = ({
	token,
	projectId,
	checklistId,
	checklistItemId
}: IFetchApiHideChecklistItem) => {
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/checklist/${checklistId}/item/${checklistItemId}/unhide`,
		token,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive
	})
}

export const fetchApiSendForInspection = ({
	token,
	projectId,
	checklistId,
	checklistItemId,
	isCustom
}: IFetchApiSendForInspection) => {
	if (!token) return

	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/checklist/${checklistId}/${itemType}/${checklistItemId}/send-for-inspection`,
		token,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive
	})
}

export const fetchApiDownloadChecklistItem = async ({
	token,
	projectId,
	checklistId,
	checklistItemId,
	isCustom
}: IFetchApiDownloadChecklistItem) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/checklist/${checklistId}/${itemType}/${checklistItemId}/export`,
		token,
		pendingKey: STATE_STORE_KEYS.CurrentChecklist
	})

	return response?.data
}

export const fetchApiSetPreDefinedComment = ({
	token,
	projectId,
	questionId,
	isCustom,
	payload
}: IFetchApiSetPreDefinedComment) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		method: FETCH_API_METHODS.Put,
		url: `/project/${projectId}/checklist/${itemType}/question/${questionId}/set-question-comments`,
		token,
		pendingKey: STATE_STORE_KEYS.SetPreDefinedComment,
		payload,
		forceFetch: true
	})
}
