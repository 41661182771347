export enum ECP_OTHER_DOCUMENTS_TABLE_KEYS {
  Name = 'Name des Dokumentes',
  CreatedAt = 'Erstellt am',
  ShippedAt = 'Versendet am',
  Actions = 'Aktionen'
}

export interface ICPOtherDocumentsTableItemTypes {
  [ECP_OTHER_DOCUMENTS_TABLE_KEYS.Name]: string
  [ECP_OTHER_DOCUMENTS_TABLE_KEYS.CreatedAt]: string[] | string
  [ECP_OTHER_DOCUMENTS_TABLE_KEYS.ShippedAt]: string[] | string
  [ECP_OTHER_DOCUMENTS_TABLE_KEYS.Actions]: number
}

export interface ICPOtherDocumentsTableProps {
  data: ICPOtherDocumentsTableItemTypes[]
  isLoading?: boolean
}

export interface ICPOtherDocumentsTableEmits {
  (e: 'click:download', payload: number): void
  (e: 'click:delete', payload: number): void
  (e: 'click:row', payload: number): void
  (e: 'scroll-end'): void
}
