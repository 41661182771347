import type { ICPDocumentCheckingParagraph } from '~/portal/components/pages'

export enum ECP_QUESTION_STATUS {
  Empty = 'EMPTY',
  Checked = 'CHECKED',
  Unchecked = 'UNCHECKED',
  Returned = 'RETURNED',
  NA = 'NA',
  NotRelevant = 'NOT_RELEVANT'
}

export interface ICPDocumentCheckingQuestionFormData {
  question: number
  status?: string
  comment?: string
  blocks: {
    id: number
    type: string
    label?: string
    answer?: any
  }[]
}

export type ICPDocumentCheckingQuestionFormDataList = Record<number, Record<number, ICPDocumentCheckingQuestionFormData>>
export interface ICPDocumentCheckingQuestionHint {
  title: string
  link?: string
  file?: {
    file_key: string
    name: string
  }[] | File[] | null
}
export interface ICPDocumentCheckingQuestion {
  id: number
  index: string
  order: number
  title: string
  hint?: ICPDocumentCheckingQuestionHint
  internalHint?: ICPDocumentCheckingQuestionHint
  isConditional?: boolean
  blocks: {
    id: number
    type: string
    subtype?: string
    label?: string
    answer?: string
  }[]
  status?: ECP_QUESTION_STATUS
  comment?: {
    id: number
    value: string
  }
  lastUpdate?: {
    date: string
    name: string
    action: string
  }
  allowEditOwnComment?: boolean
  comments?: {
    id: string
    text: string
    textMark: string
  }[]
  preDefinedComments?: number[]
  ownComment?: string | null
}

export interface ICPDocumentCheckingQuestionInpectionPayload {
  id: number
  status?: ECP_QUESTION_STATUS
  comment?: {
    id?: number
    value: string
  }
}
export interface ICPDocumentCheckingQuestionListProps {
  paragraphs: ICPDocumentCheckingParagraph[]
  iteration: number
  disabled?: boolean
  status?: string
}
