import { defineStore } from 'pinia'
import type { components } from 'schema.ts'

import type {
	IFetchApiAllChats,
	IFetchApiChatAllMessageCount,
	IFetchApiChatMessageAdd,
	IFetchApiChatMessageEmailNotify,
	IFetchApiChatMessageMarkAsRead,
	IFetchApiChatNoteAdd,
	IFetchApiChatNoteDelete,
	IFetchApiChatNoteUpdate,
	IFetchApiChatPayload
} from '~/portal/api'
import {
	fetchApiAllChats,
	fetchApiChatAllMessageCount,
	fetchApiChatMessageAdd,
	fetchApiChatMessageCount,
	fetchApiChatMessageEmailNotify,
	fetchApiChatMessageMarkAsRead,
	fetchApiChatMessages,
	fetchApiChatNoteAdd,
	fetchApiChatNoteDelete,
	fetchApiChatNoteList,
	fetchApiChatNoteSend,
	fetchApiChatNoteUpdate
} from '~/portal/api'
import type { IChatStore } from '~/portal/stores'

export const useChatStore = defineStore('chatStore', {
	state: (): IChatStore => ({
		countAll: {
			notifications: 0,
			messages: 0,
			projects: []
		},
		count: {},
		noteList: {},
		chatList: [],
		loading: {},
		chatCount: 0
	}),
	getters: {
		messageCount (state: IChatStore) {
			return (projectId: number) => state.count[projectId]?.messages || 0
		},
		noteListSorted (state: IChatStore) {
			return (projectId: number): components['schemas']['ListNote'][] => {
				return state.noteList[projectId]?.sort((a, b) => {
					const dateA = a.created_at ? new Date(a.created_at) : new Date()
					const dateB = b.created_at ? new Date(b.created_at) : new Date()

					return dateA.getTime() - dateB.getTime()
				})
			}
		},
		chatListSorted (state: IChatStore) {
			return (projectId: number): components['schemas']['ListChatMessages'][] => {
				return state.chatList
					.find((chat) => chat.id === projectId)
					?.messages
					?.sort((a, b) => {
						const dateA = a.created_at ? new Date(a.created_at) : new Date()
						const dateB = b.created_at ? new Date(b.created_at) : new Date()

						return dateA.getTime() - dateB.getTime()
					}) || []
			}
		},
		notificationAllAmount (state: IChatStore) {
			return state.countAll.notifications
		},
		chatAllMessageAmount (state: IChatStore) {
			return state.countAll.messages
		}
	},
	actions: {
		async fetchChatList (payload: IFetchApiAllChats) {
			const response = await fetchApiAllChats(payload)

			if (response && response.results) {
				this.chatList = response.results

				this.count = Object.fromEntries(
					response.results.map((chat) => [chat.id, {
						messages: chat.messages_count
					}])
				)
				this.chatCount = response.count || 0
			}
		},
		async fetchChatMessages (
			payload: IFetchApiChatPayload
		) {
			if (this.loading[payload.projectId]) {
				return
			}

			let response

			try {
				this.loading[payload.projectId] = true
				response = await fetchApiChatMessages(payload)
			} finally {
				this.loading[payload.projectId] = false
			}

			if (response && response.data) {
				const index = this.chatList.findIndex((chat) => chat.id === payload.projectId)

				if (index !== -1) {
					this.chatList[index].messages = response.data
				} else {
					this.chatList.push({
						id: +payload.projectId,
						name: '',
						messages_count: response.data.length,
						messages: response.data
					})
				}
			}
		},
		async fetchChatMessageAdd (
			payload: IFetchApiChatMessageAdd
		) {
			await fetchApiChatMessageAdd(payload)
			await this.fetchChatMessages({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchChatMessageMarkAsRead (
			payload: IFetchApiChatMessageMarkAsRead
		) {
			const response = await fetchApiChatMessageMarkAsRead(payload)

			if (response) {
				const index = this.chatList.findIndex((chat) => chat.id === payload.projectId)

				if (index !== -1) {
					const message = this.chatList[index]?.messages?.find(
						({ id }) => id === payload.messageId
					)
					if (message) {
						message.is_seen = true

						const count = this.count[payload.projectId]

						if (count?.messages) {
							count.messages--
						}
					}
				}
			}
		},
		async fetchChatMessageCount (
			payload: IFetchApiChatPayload
		) {
			const response = await fetchApiChatMessageCount(payload)

			if (response && response.data) {
				this.count[payload.projectId] = response.data
			}

			return response?.data
		},
		async fetchApiChatAllMessageCount (
			payload: IFetchApiChatAllMessageCount
		) {
			const response = await fetchApiChatAllMessageCount(payload)

			if (response) {
				this.countAll = response.data
			}
		},
		async fetchChatNoteList (
			payload: IFetchApiChatPayload
		) {
			const response = await fetchApiChatNoteList(payload)

			if (response && response.data) {
				this.noteList[payload.projectId] = response.data
			}
		},
		async fetchApiChatNoteAdd (
			payload: IFetchApiChatNoteAdd
		) {
			await fetchApiChatNoteAdd(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchApiChatNoteDelete (
			payload: IFetchApiChatNoteDelete
		) {
			await fetchApiChatNoteDelete(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchApiChatNoteSend (
			payload: IFetchApiChatNoteDelete
		) {
			await fetchApiChatNoteSend(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
			await this.fetchChatMessages({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchChatNoteUpdate (
			payload: IFetchApiChatNoteUpdate
		) {
			await fetchApiChatNoteUpdate(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchApiChatMessageEmailNotify (
			payload: IFetchApiChatMessageEmailNotify
		) {
			await fetchApiChatMessageEmailNotify(payload)
			await this.fetchChatMessages({
				token: payload.token,
				projectId: payload.projectId
			})
		}
	}
})
