import { isEmpty } from 'lodash'
import { storeToRefs } from 'pinia'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useCreateAccountStore, useUserStore } from '~/portal/stores'

export const emailVerificationMiddleWare = async (
	to: RouteLocationNormalized,
	_: RouteLocationNormalized,
	next: NavigationGuardNext
): Promise<void> => {
	const userStore = useUserStore()
	const accountStore = useCreateAccountStore()
	// eslint-disable-next-line @typescript-eslint/typedef
	const { account, personalInfo } = storeToRefs(accountStore)
	const { user } = storeToRefs(userStore)

	const isEmptyAccount = Object.values(account.value).some((value) => !!value)
	const isEmptyPersonalInfo = Object.values(personalInfo.value).some((value) => !!value)

	if ((!isEmpty(user.value) && !user.value.is_verified) || to.query.email) {
		next()
	}

	if (
		(!isEmptyAccount || !isEmptyPersonalInfo)
	) {
		next({ name: 'account-create' })
	} else {
		next()
	}
}
