import type { AxiosError } from 'axios'

import { axiosInstance } from '~/quality-management/api'
import type {
	IEmployee,
	IEmployeeDepartment,
	IEmployeeEditForm,
	IEmployeeRole,
	IEmployeeTableData
} from '~/quality-management/api/employee-api/types'

export const employeeApi = {
	getEmployee: async (id: number): Promise<{data: IEmployee} | AxiosError> => {
		return axiosInstance.get(`/users/${id}`)
	},
	getTableData: async (userId: number, tableId:number, year: number):Promise<{data: IEmployeeTableData}> => {
		return axiosInstance.get(`/users/${userId}/tables/${tableId}?year=${year}`)
	},
	patchTableData: async (userId: number, tableId: number, body: any) => {
		return axiosInstance.patch(`/users/${userId}/tables/${tableId}`, body)
	},
	getDepartments: async (): Promise<{data: IEmployeeDepartment[]}> => {
		return axiosInstance.get('/departments')
	},
	getRoles: async (): Promise<{data: IEmployeeRole[]}> => {
		return axiosInstance.get('/roles')
	},
	patchEmployeeInfo: async (userId: number, employeeData:IEmployeeEditForm): Promise<{message:string}> => {
		return axiosInstance.patch(`/users/${userId}`, employeeData)
	}
}
