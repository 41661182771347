<script setup lang="ts">
import { computed, ref } from 'vue'

import { useVuelidate } from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'

import type {
	IFetchApiChatFile, IFetchApiChatMessageAdd, IFetchApiChatNoteAdd
} from '~/portal/api'
import {
	CPButton,
	CPCheckbox,
	CPFileUpload,
	CPTextEditor
} from '~/portal/components/base'
import type { ICPChatReplyPropTypes } from '~/portal/components/pages'
import { CPChatType } from '~/portal/components/pages'
import { AccessScopesEnum, useAccessToken, useAuthData, useSnackbar } from '~/portal/composables'
import { useChatStore } from '~/portal/stores'
import { fileToBase64, getErrors } from '~/portal/utils'

const props = withDefaults(
	defineProps<ICPChatReplyPropTypes>(), {
		type: CPChatType?.Default,
		disabled: false
	}
)

const chatStore = useChatStore()
const { checkHasScope } = useAuthData()

const { token } = useAccessToken()

const { showSnackbar } = useSnackbar()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const {
	fetchChatNoteUpdate,
	fetchApiChatNoteAdd,
	fetchChatMessageAdd
} = chatStore

const files = ref<File[]>()
const editor = ref()
const isSending = ref(false)
const isSentToEmail = ref(false)
const message = ref('')

const rules = computed(() => {
	return {
		message: {
			requiredIf: helpers.withMessage('Dieses Feld darf nicht leer sein', requiredIf(!files.value?.length))
		}
	}
})

const v$ = useVuelidate(rules, { message })

const buttonLabel = computed(() => (
	props.noteId ? 'Bearbeiten' : 'Schicken'
))

const showSendEmail = computed(() => (
	props.type === CPChatType.Default && checkHasScope([
		AccessScopesEnum.PORTAL_TEAM_LEAD,
		AccessScopesEnum.PORTAL_PROJECT_MANAGER,
		AccessScopesEnum.PORTAL_EMPLOYEE
	])
))

const resetForm = (): void => {
	if (editor.value) {
		editor.value.editor?.commands?.setContent('')
	}

	message.value = ''
	isSentToEmail.value = false
	files.value = undefined
	v$.value.$reset()
	emit('success')
}

const updateMessage = async (): Promise<void> => {
	if (!props.noteId) {
		return
	}

	return await fetchChatNoteUpdate({
		token: token.value,
		text: message.value,
		projectId: props.projectId,
		noteId: props.noteId
	})
}

const getFileList = async (value?: File[]): Promise<IFetchApiChatFile[]> => {
	if (!value) {
		return []
	}

	const list: Promise<string | null>[] = value.map(async (item) => {
		return await fileToBase64(item)
	})

	const values = await Promise.all(list)

	return values.map((item, index) => {
		return {
			name: value[index].name,
			base64: item?.replace(/^data:.+;base64,/, '') || undefined
		}
	}) as IFetchApiChatFile[]
}

const addMessage = async (): Promise<void> => {
	const fileFormatted = await getFileList(files.value)

	if (props.type === CPChatType.Default) {
		const payload: IFetchApiChatMessageAdd = {
			token: token.value,
			text: message.value,
			projectId: props.projectId,
			emailNotify: isSentToEmail.value
		}

		if (fileFormatted?.length) {
			payload.files = fileFormatted
		}

		return await fetchChatMessageAdd(payload)
	} else {
		const payload: IFetchApiChatNoteAdd = {
			token: token.value,
			projectId: props.projectId,
			text: message.value
		}

		if (fileFormatted?.length) {
			payload.files = fileFormatted
		}

		return await fetchApiChatNoteAdd(payload)
	}
}

const sendMessage = async (): Promise<void> => {
	if (props.noteId) {
		return await updateMessage()
	} else {
		return await addMessage()
	}
}

const onClick = async (): Promise<void> => {
	const isValid = await v$.value.$validate()

	if (isValid) {
		try {
			isSending.value = true

			await sendMessage()

			resetForm()
		} finally {
			isSending.value = false
		}
	}
}

defineExpose({
	editor
})
</script>

<template>
  <div class="cp-chat-replay">
    <CPTextEditor
      ref="editor"
      v-model="message"
      :disabled="isSending || disabled"
      class="cp-chat-replay__replay-editor"
      placeholder="Geben Sie Ihre Nachricht ein"
      :error="v$.message.$error"
      :error-messages="getErrors(v$.message.$errors)"
      @input="v$.message.$touch"
      @keyup="onClick"
    />
    <div class="cp-chat-replay__bottom">
      <CPCheckbox
        v-if="showSendEmail"
        v-model="isSentToEmail"
        :disabled="disabled"
        label="Senden Sie diese Nachricht zusätzlich an die E-Mail-Adressen der Chatteilnehmer"
      />
      <CPButton
        color="main"
        :loading="isSending"
        @click="onClick"
        :disabled="disabled"
        class="cp-chat-replay__submit"
      >
        {{ buttonLabel }}
      </CPButton>
      <div class="cp-chat-replay__files">
        <CPFileUpload
          v-model="files"
          :multiple="true"
          :disabled="disabled"
          label="Dateien hochladen"
          accept="*"
          :max-size="50"
          @error="showSnackbar('Datei ist zu groß', 'cp-base-red')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .cp-chat-replay {
    @apply
      p-4
      grid
      gap-4
      sm:(
        p-6
      )
    ;

    &__files {
      @apply
        flex
        w-full
      ;
    }

    &__replay-editor {
      .cp-text-editor__editor {
        @apply
          min-h-2
        ;
      }
    }

    &__bottom {
      @apply
        flex
        items-start
        flex-col
        flex-wrap
        gap-4
        md:(
          flex-row
          justify-between
          items-center
        )
      ;
    }

    &__submit {
      @apply
        md:(
          ml-a
        )
      ;
    }
  }
</style>
