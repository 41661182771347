export enum ECP_REPORTS_STATUSES {
  Unchecked = 'UNCHECKED',
  Checked = 'CHECKED',
}

export enum ECP_REPORTS_TABLE_COLUMNS {
  Name = 'Name des Berichts',
  Service = 'Dienst',
  Status = 'Status',
  Result = 'Ergebnis',
  Date = 'Erstellt am',
  SentAt = 'Versendet am',
  Actions = 'Aktionen',
}

export interface ICPReportsTableRow {
  [ECP_REPORTS_TABLE_COLUMNS.Name]: string
  [ECP_REPORTS_TABLE_COLUMNS.Service]: string
  [ECP_REPORTS_TABLE_COLUMNS.Status]: string
  [ECP_REPORTS_TABLE_COLUMNS.Result]: string
  [ECP_REPORTS_TABLE_COLUMNS.Date]: string[] | string
  [ECP_REPORTS_TABLE_COLUMNS.SentAt]: string[] | string
  [ECP_REPORTS_TABLE_COLUMNS.Actions]: number
}

export interface ICPReportsTableProps {
  data: ICPReportsTableRow[]
  loading?: boolean
}
