import type { AxiosResponse } from 'axios'
import axios from 'axios'

import {
	toCamelCase,
	toSnakeCase
} from '~/quality-management/api/utils/toCamelCase'
import { globalRouter } from '~/quality-management/globalRouter.ts'
import { useAuthStore } from '~/quality-management/stores/authStore/useAuthStore'
import { QualityManagementRoutes } from '~/router/modules/quality-management/qualityManagementTypes'

import { ResponseStatuses } from './types'

export const axiosInstance = axios.create({
	baseURL: import.meta.env.VITE_HRQM_API_URL,
	timeout: 1200000,
	headers: {
		'Content-Type': 'application/json'
	}
})

axiosInstance.interceptors.request.use(
	config => {
		const authStore = useAuthStore()
		const token = authStore.token
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		if (config.data && typeof config.data === 'object') {
			config.data = toSnakeCase(config.data)
		}
		return config
	},
	error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		const isBlob = response.config.responseType === 'blob' || response.data instanceof Blob
		if (!isBlob && response.data) {
			response.data = toCamelCase(response.data)
			return response.data
		}
		return response
	},
	error => {
		if (error.response) {
			if (error.response.status === ResponseStatuses.Forbidden) {
				globalRouter.router?.push({ name: QualityManagementRoutes.NoAccess })
				return error
			}
		}
		return Promise.reject(error)
	}
)
