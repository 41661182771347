export enum ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS {
  Id = 'ID',
  DocumentName = 'Name des Dokuments',
  LastUpdate = 'Letztes Update',
  Comment = 'Kommentar',
  Iteration = 'Iteration',
  Status = 'Status',
  IsHidden = 'Ist versteckt',
  Action = 'Aktion'
}

export enum ECP_DOCUMENTS_CHECKLIST_TABLE_ACTIONS {
  Edit = 'Edit',
  Delete = 'Delete',
  Hide = 'Hide',
  Unhide = 'Unhide'
}

export interface ICPDocumentsChecklistTableActions {
  label: ECP_DOCUMENTS_CHECKLIST_TABLE_ACTIONS
  action: ECP_DOCUMENTS_CHECKLIST_TABLE_ACTIONS
}

export interface ICPDocumentsChecklistTableData {
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.DocumentName]: string
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.LastUpdate]: {
    updatedAt: string
    updatedBy: string
  }
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Comment]: string
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Iteration]: number
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Status]: string
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.IsHidden]: boolean
  [ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Action]: {
    id: number
    isCustom: boolean
    isHidden?: boolean
  }
}

export interface ICPDocumentsChecklistProps {
  data: ICPDocumentsChecklistTableData[]
  loading?: boolean
  disabled?: boolean
  isInternalChecklist?: boolean
}
