export enum SINGLE_PROJECT_ROUTES_NAMES {
    CHAT = 'chat',
    SingleProject = 'singleProject',
    ProjectSettings = 'projectSettings',
    DocumentsChecklist = 'documentsChecklist',
    InternalChecklist = 'internalChecklist',
    ActivityLogs = 'activityLogs',
    GeneralInformation = 'generalInformation',
    DocumentChecking = 'documentChecking',
    Products = 'Products',
    PdfViewerInvoice = 'PdfViewerInvoice',
    PdfViewerOffer = 'PdfViewerOffer',
    PdfViewerCertificate = 'PdfViewerCertificate',
    PdfViewerReport = 'PdfViewerReport',
    PdfViewerOtherDocument = 'PdfViewerOtherDocument',
    Calculations = 'Calculations',
    BusinessDocumentation = 'BusinessDocumentation',
    TechnicalDocumentation = 'TechnicalDocumentation',
}

export interface IPdfViewerCertificateParams {
    projectId: string | number
    certificateId: string | number
    name: string
    file: string
}
