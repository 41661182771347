import { defineStore } from 'pinia'

import type {
	IFetchApiGetChecklist
} from '~/portal/api'
import {
	createDocumentsChecklistItem,
	fetchApiDeleteChecklistItem,
	fetchApiGetCurrentChecklist,
	fetchApiGetInternalChecklist,
	fetchGetChecklistItem
} from '~/portal/api'
import type { ICPDocumentsChecklistStoreTypes } from '~/portal/stores'

export const useDocumentsChecklistStore = defineStore('documentsChecklistStore', {
	state: (): ICPDocumentsChecklistStoreTypes => ({
		checklist: {},
		checklistItem: {},
		isInternal: false
	}),
	actions: {
		async fetchCurrentChecklist (
			payload: IFetchApiGetChecklist & { isInternal: boolean }
		) {
			try {
				const response = payload.isInternal
					? await fetchApiGetInternalChecklist(payload)
					: await fetchApiGetCurrentChecklist(payload)

				if (response) {
					this.checklist = response
					this.isInternal = payload.isInternal
				}
			} catch (error) {
				this.checklist = {}
				throw error
			}
		},
		async deleteChecklistItem (payload: {
      token: string
      checklistItemId: number
      isCustom?: boolean
    }) {
			if (!this.checklist.id || !payload.token) return

			const response = await fetchApiDeleteChecklistItem({
				token: payload.token,
				checklistItemId: payload.checklistItemId,
				checklistId: this.checklist.id,
				isCustom: payload.isCustom
			})

			if (response) {
				this.checklist.items = this.checklist.items?.filter(
					(item: any) => item.id !== payload.checklistItemId
				)
			}
		},
		async createChecklistItem (payload: {
      token: string
      projectId: number
      isCustom?: boolean
      data: {
        name: string
        description: string
      }
    }) {
			if (!this.checklist.id) return

			const response = await createDocumentsChecklistItem({
				...payload,
				checklistId: this.checklist.id
			})

			if (response) {
				await this.fetchCurrentChecklist({
					token: payload.token,
					projectId: payload.projectId,
					isInternal: this.isInternal
				})
			}
		},
		async fetchChecklistItem (payload: {
      token: string
      checklistItemId: number
      checklistId: number
      projectId?: number
    }) {
			const response = await fetchGetChecklistItem(payload)
			if (response) {
				this.checklistItem = response
			}
		}
	}
})
