import { defineStore } from 'pinia'

import type { IFetchApiDeleteOtherDocument, IFetchApiGetOtherDocuments } from '~/portal/api'
import { fetchApiDeleteOtherDocument, fetchApiGetOtherDocuments } from '~/portal/api'

import type { IOtherDocumentsStoreProps } from './otherDocumentsStoreTypes'

export const useOtherDocumentsStore = defineStore('other-documents', {
	state: (): IOtherDocumentsStoreProps => ({
		documents: [],
		currentPage: 1,
		pageCount: 0,
		isPagination: false
	}),
	actions: {
		resetDocuments () {
			this.documents = []
			this.currentPage = 1
			this.pageCount = 0
			this.isPagination = false
		},

		async fetchDocuments (payload: IFetchApiGetOtherDocuments) {
			const response = await fetchApiGetOtherDocuments({
				...payload,
				params: {
					page: this.currentPage
				}
			})

			if (response) {
				if (response.results) {
					this.documents.push(...response.results)
				}

				this.pageCount = response.count ?? 0
				this.currentPage = Number(response.next) || (Number(response.previous) + 1) || 1
				this.isPagination = !!response.next
			}
		},
		async deleteDocument (payload: IFetchApiDeleteOtherDocument) {
			try {
				await fetchApiDeleteOtherDocument(payload)
				this.resetDocuments()
				await this.fetchDocuments({
					token: payload.token,
					projectId: payload.projectId
				})
			} catch (error) {
				throw new Error(error as string)
			}
		}
	}
})
