export enum ECP_EZE_TABLE_HEADING_KEYS {
  Energy_System = 'Typ des Energiesystems',
  Manufacturer = 'Hersteller',
  Type = 'Typ',
  Qty = 'Anzahl',
  Output_kWel = 'Leistung (kWel)',
  Total_power = 'Gesamtleistung',
  IBN_Date = 'IBN-Datum',
  Action = 'Aktion'
}

export enum ECP_EZE_TABLE_KEYS {
  New = 'New',
  Old = 'Old',
  Moderate = 'Moderate',
}

export type TEZETableKeys = 'New' | 'Old' | 'Moderate'

export enum ECP_EZE_TABLE_ACTIONS {
  Delete = 'Delete',
  Edit = 'Edit'
}

export interface ICPEZETableActionsTypes {
  label: string
  action: ECP_EZE_TABLE_ACTIONS
}

export interface ICPEZETableItemTypes {
  [ECP_EZE_TABLE_HEADING_KEYS.Energy_System]: string
  [ECP_EZE_TABLE_HEADING_KEYS.Manufacturer]?: string
  [ECP_EZE_TABLE_HEADING_KEYS.Type]?: string
  [ECP_EZE_TABLE_HEADING_KEYS.Qty]?: number
  [ECP_EZE_TABLE_HEADING_KEYS.Output_kWel]?: string
  [ECP_EZE_TABLE_HEADING_KEYS.Total_power]?: number
  [ECP_EZE_TABLE_HEADING_KEYS.IBN_Date]?: string
  [ECP_EZE_TABLE_HEADING_KEYS.Action]: any // to pass id here
}

export interface ICPEZETablePropTypes {
  modelValue: ICPEZETableItemTypes[]
  title: string
  ezeType: 'new' | 'moderate' | 'old'
  subtitle: string
  loading?: boolean
  disabled?: boolean
  isShowAddProduct?: boolean
}

export interface ICPEZETableEmitTypes {
  id: ICPEZETableItemTypes[ECP_EZE_TABLE_HEADING_KEYS.Action]
}
