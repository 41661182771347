<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

import { CPBreadcrumbs, CPButton, CPLink } from '~/portal/components/base'
import { CPBox, CPHeading, CPTypography } from '~/portal/components/layout'
import {
	AccessScopesEnum,
	useAccessToken,
	useAuthData,
	useBreadcrumbs
} from '~/portal/composables'
import {
	useDraftsStore,
	useProjectCreationStore,
	useProjectsStore,
	useTemplatesStore,
	useUserStore
} from '~/portal/stores'
import { HomeRoutesNamesEnum, ProjectRoutesNamesEnum } from '~/router'

/* eslint-disable @typescript-eslint/typedef */
const { token } = useAccessToken()
const { checkHasScope } = useAuthData()

const {
	setBreadcrumbs,
	breadcrumbs
} = useBreadcrumbs()

setBreadcrumbs([
	{
		label: 'Startseite'
	}
])

const {
	resetTemplateNameAndId
} = useTemplatesStore()

const {
	resetDraftId
} = useDraftsStore()

const {
	resetProjectCreationState
} = useProjectCreationStore()

const projectStore = useProjectsStore()
const { projectCount } = storeToRefs(projectStore)
const {
	fetchGetProjectCount
} = projectStore

const { fetchCurrentUser } = useUserStore()

const router = useRouter()
const route = useRoute()

const isPortalRole = computed(() => {
	return checkHasScope([
		AccessScopesEnum.PORTAL_TEAM_LEAD,
		AccessScopesEnum.PORTAL_PROJECT_MANAGER
	])
})

const isPortalRoleEmployee = computed(() => {
	return checkHasScope([
		AccessScopesEnum.PORTAL_EMPLOYEE
	])
})

const headingTabs = computed(() => {
	const projectTab = {
		title: 'Projekte',
		subtitle: projectCount.value.project_count,
		to: '/',
		class: [HomeRoutesNamesEnum.homeProjects],
		active: route.name === HomeRoutesNamesEnum.homeProjects
	}

	if (isPortalRole.value) {
		return [
			projectTab,
			{
				title: 'Kunden',
				subtitle: projectCount.value.company_count,
				to: `/${HomeRoutesNamesEnum.clients}`,
				class: [HomeRoutesNamesEnum.clients],
				active: route.name === HomeRoutesNamesEnum.clients
			}
		]
	}

	if (!isPortalRoleEmployee.value) {
		return [
			projectTab,
			{
				title: 'Entwürfe',
				subtitle: projectCount.value.draft_count,
				to: `/${HomeRoutesNamesEnum.drafts}`,
				class: [HomeRoutesNamesEnum.drafts],
				active: route.name === HomeRoutesNamesEnum.drafts
			},
			{
				title: 'Vorlagen',
				subtitle: projectCount.value.template_count,
				to: `/${HomeRoutesNamesEnum.templates}`,
				class: [HomeRoutesNamesEnum.templates],
				active: route.name === HomeRoutesNamesEnum.templates
			}
		]
	}

	return [
		projectTab
	]
})

const classes = computed(() => {
	const cl = 'cp-home'

	return {
		[cl]: true,
		[`${cl}--project`]: route.name === HomeRoutesNamesEnum.homeProjects
	}
})

const hideTabs = computed(() => (
	route.name === HomeRoutesNamesEnum.chats
))

const onCreateProject = (): void => {
	resetProjectCreationState()
	resetDraftId()
	resetTemplateNameAndId()
	router.push({ name: ProjectRoutesNamesEnum.applicant })
}

watch(token, (value) => {
	if (!value) {
		return
	}

	fetchGetProjectCount({
		token: value
	})

	fetchCurrentUser(value)
})

onMounted(async () => {
	resetDraftId()
})
</script>

<template>
  <div
    :class="classes"
  >
    <CPBox>
      <CPHeading>
        <template #breadcrumbs>
          <CPBreadcrumbs :data="breadcrumbs" />
        </template>

        <template #prepend>
          <div class="cp-home__prepend" v-if="!hideTabs">
            <div class="cp-home__prepend-inner">
              <template
                v-for="tab in headingTabs"
                :key="tab.to"
              >
                <CPLink
                  :to="tab.to"
                  :class="[
                    'cp-home__prepend-link',
                    `cp-home__prepend-link--${tab.class}`,
                    { 'cp-home__prepend-link--active': tab.active }
                  ]"
                >
                  {{ tab.title }}
                  <span
                    v-if="tab?.subtitle"
                    class="cp-home__prepend-link-sub"
                  >
								({{ tab.subtitle }})
							</span>
                </CPLink>
              </template>
            </div>
          </div>
          <CPTypography
            v-else
            variant="h1"
          >
            Chat
          </CPTypography>
        </template>

        <template v-if="!hideTabs"  #append>
          <div class="l-create-project__append">
            <CPButton
              @click="onCreateProject"
            >
              Projekt erstellen
            </CPButton>
          </div>
        </template>
      </CPHeading>
    </CPBox>

    <VDivider class="cp-home__separator" />

    <CPBox class="cp-home__content">
      <RouterView />
    </CPBox>
  </div>
</template>

<style scoped lang="scss">
  .cp-home {
    @apply
      flex
      flex-col
      flex-grow
    ;

    &--project {
      .cp-home__separator {
        @apply
          <lg:(
            hidden
          )
        ;
      }
    }

    &__content {
      @apply
        flex
        flex-col
        flex-grow
        overflow-x-hidden
      ;
    }

    &__prepend {
      @apply
        flex
        items-center
        min-h-11
      ;

      &-inner {
        @apply
          flex
          items-baseline
          gap-2
          sm:(
            gap-3
          )
          lg:(
            gap-4
          )
      }

      &-link {
        @apply
          gap-1
          fw-500
          inline-flex
          items-baseline
          color-cp-neutral-400
          hover:color-cp-neutral-700
          font-size-5
          lg:(
            font-size-6
          )
        ;
          letter-spacing: 0.02em;

        &--active {
          @apply
            color-cp-neutral-700
            font-size-7
            lg:(
              font-size-8
            )
            mb-0
          ;

          .cp-home__prepend-link-sub {
            @apply
              font-size-4
              lg:(
                font-size-5
              )
            ;
          }
        }

        &-sub {
          @apply
            fw-500
            font-size-[0.875rem]
            lg:(
              font-size-4
            )
          ;
        }
      }
    }
  }
</style>
