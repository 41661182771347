import type { components } from 'schema'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiGetOtherDocuments {
  projectId: number
  token: IFetchApiProps['token']
  params?: {
    page?: number
  }
}

export interface IFetchApiDeleteOtherDocument {
	token: IFetchApiProps['token']
	projectId: number
	documentId: number
}

export interface IFetchApiDownloadOtherDocument {
	token: IFetchApiProps['token']
	projectId: number
	documentId: number
}

export interface IFetchApiUploadOtherDocument {
	token: IFetchApiProps['token']
	projectId: number
	payload: components['schemas']['UploadCommercialDocument']
}

export interface IFetchApiSendOtherDocumentToClient {
  token: IFetchApiProps['token']
  projectId: number
  documentId: number
}

export const fetchApiGetOtherDocuments = async (
	{
		token,
		projectId,
		params
	}: IFetchApiGetOtherDocuments
): Promise<components['schemas']['PaginatedGetProjectDocumentList'] | undefined> => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${projectId}/commercial-documents`,
		token,
		pendingKey: STATE_STORE_KEYS.OtherDocuments,
		params
	})

	return response?.data
}

export const fetchApiDeleteOtherDocument = async ({
	token,
	projectId,
	documentId
}: IFetchApiDeleteOtherDocument) => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/project/${projectId}/commercial-documents/${documentId}/delete`,
		token,
		pendingKey: STATE_STORE_KEYS.OtherDocuments
	})

	return response?.data
}

export const fetchApiDownloadOtherDocument = async ({
	token,
	projectId,
	documentId
}: IFetchApiDownloadOtherDocument) => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${projectId}/commercial-documents/${documentId}/download`,
		token,
		pendingKey: STATE_STORE_KEYS.OtherDocuments
	})

	return response?.data
}

export const fetchApiUploadOtherDocument = async (
	{
		token,
		projectId,
		payload
	}: IFetchApiUploadOtherDocument
): Promise<components['schemas'][] | undefined> => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/commercial-documents/upload`,
		token,
		pendingKey: STATE_STORE_KEYS.UploadOtherDocument,
		payload
	})

	return response?.data
}

export const fetchApiSendOtherDocumentToClient = async ({
	token,
	projectId,
	documentId
}: IFetchApiSendOtherDocumentToClient) => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${projectId}/commercial-documents/${documentId}/notify-all`,
		token,
		pendingKey: STATE_STORE_KEYS.OtherDocuments
	})

	return response?.data
}
