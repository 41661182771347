export enum QualityManagementRoutes {
  Dashboard = 'dashboard',
  QMEmployee = 'qm-employee',
  QualityManagement = 'quality-management',
  GeneralInfo = 'qm-general-info',
  TablesView = 'qm-tables-view',
  Files = 'qm-files',
  NotFound = '404',
  EditTable = 'qm-edit-table',
  EditFiles = 'qm-edit-files',
  Redirect = 'qm-redirect',
  NoAccess = 'qm-no-access'
}

export enum QualityManagementPageTitles {
  Dashboard = 'Dashboard',
  Employee = 'Employee',
}

export enum EmployeeRoutesName {
  GeneralInfo = 'general-info',
  Files = 'files',
}
