<script setup lang="ts">
import { computed } from 'vue'

import type { ICPFileUploadItemPropType } from '~/portal/components/base'
import { CPSvgIcon, formatFileSize } from '~/portal/components/base'

const props = withDefaults(
	defineProps<ICPFileUploadItemPropType>(),
	{
		showIcon: true,
		allowDownload: true
	}
)

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'download'): void
}>()

const onDeleteFile = (): void => emit('delete')

const allowedExtensionsToPrewiew = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg', 'webp']

const isFilePreviewable = computed(() => {
	if (props.downloadLink && props.allowPreview) {
		const extension = props.name.split('.').pop()?.toLowerCase()
		return allowedExtensionsToPrewiew.includes(extension || '')
	} else {
		return false
	}
})

const onDownLoad = async (e: MouseEvent) => {
	e.preventDefault()

	if (!props.downloadLink) {
		return
	}

	const response = await fetch(props.downloadLink)
	const blob = await response.blob()
	const link = document.createElement('a')
	link.href = URL.createObjectURL(blob)
	link.download = props.name || 'download'
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
	URL.revokeObjectURL(link.href)
	emit('download')
}

const onClickPreview = () => {
	if (isFilePreviewable.value) {
		window.open(props.downloadLink, '_blank')
	} else if (props.allowPreview) {
		onDownLoad(new MouseEvent('click'))
	}
}
</script>

<template>
  <div
    :class="[
      'cp-file-upload-item',
      {
        'cp-file-upload-item--inverse': inverse
      }
    ]"
  >
    <CPSvgIcon
      v-if="showIcon"
      name="file"
      :class="[
        'cp-file-upload-item__icon',
        { 'cursor-pointer': allowPreview }
      ]"
      :size="51"
      @click="onClickPreview"
    />
    <div
      :class="{'cp-file-upload-item__info': showIcon}"
    >
      <span class="cp-file-upload-item__name">
        {{ name }}
      </span>
      <div class="flex">
        <span
          v-if="size"
          class="cp-file-upload-item__size"
        >
          {{ formatFileSize(size) }}
        </span>
        <span
          v-if="isEditable"
          class="cp-file-upload-item__delete"
          @click="onDeleteFile"
        >
          Löschen
        </span>
        <a
          v-if="downloadLink && allowDownload"
          :class="{ 'ml-2': isEditable }"
          :download="name"
          :href="downloadLink"
          class="cp-file-upload-item__download"
          @click="onDownLoad"
        >
          Herunterladen
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .cp-file-upload-item {
    @apply
      flex
      items-center
    ;

    letter-spacing: 0.25px;

    &--inverse {
      .cp-file-upload-item__icon {
        @apply
        text-cp-neutral-100
        ;
      }

      .cp-file-upload-item__name {
        @apply
          text-cp-neutral-100
        ;
      }

      .cp-file-upload-item__size {
        @apply
        text-cp-neutral-200
        ;
      }

      .cp-file-upload-item__download {
        @apply
        text-cp-neutral-100
        ;
      }
    }

    &__icon {
      @apply
        text-cp-accent-200
        flex-shrink-0
      ;
    }

    &__info {
      @apply
        ml-4
        md:ml-6
      ;
    }

    &__name {
      @apply
        inline-block
        font-medium
        text-[16px]
        text-cp-neutral-700
        -mb-0.5
        break-all
      ;
    }

    &__size {
      @apply
        font-normal
        text-sm
        text-cp-neutral-500
      ;

      &::after {
        content: '•';

        @apply
          ml-3
          mr-2
          text-cp-neutral-300

        ;
      }
    }

    &__delete {
      @apply
        text-cp-base-red
        text-sm
        font-medium
        cursor-pointer
      ;
    }

    &__download {
      @apply
        text-cp-neutral-400
        text-sm
        font-medium
        cursor-pointer
        decoration-none
      ;
    }
  }
</style>
