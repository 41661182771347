import type { App } from 'vue'
import type { Router } from 'vue-router'

import * as Sentry from '@sentry/vue'

export const initSentry = ({ app, router }: {app: App; router?: Router}) => {
	return Sentry.init({
		app,
		enabled: import.meta.env.VITE_ENV_NAME !== 'local',
		environment: import.meta.env.VITE_ENV_NAME,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration()
		],

		sampleRate: import.meta.env.VITE_ENV_NAME === 'dev'
			? 0.01
			: 0.2,
		tracesSampleRate: 0.2,
		tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
		replaysSessionSampleRate: 0.05,
		replaysOnErrorSampleRate: 0.5
	})
}
