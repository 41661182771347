import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

const pendingKey = STATE_STORE_KEYS.InvoiceInteractive

export interface IFetchApiSetInvoiseStatus {
  token: IFetchApiProps['token']
  projectId: number
  invoiceId: number
  status: components['schemas']['GetInvoice']['status']
}

export interface IFetchApiCancelInvoice {
  token: IFetchApiProps['token']
  invoiceId: number
  projectId: string
  payload: {
    number: string
  }
}

export const fetchApiGetInvoiceList = async (
	payload: {
		token: IFetchApiProps['token']
		id?: number
    params?: {
      page?: number
      search?: string
      pm?: string
      status?: string
      date_from?: string
      date_to?: string
      service?: string
      type?: string
    }
	}
) => {
	if (!payload.token) return

	const url = payload.id
		? `/project/${payload.id}/invoice/list`
		: '/project/invoice/list'

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url,
		payload: {
			id: payload.id
		},
		pendingKey,
		token: payload.token,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetInvoiceDownload = async (
	payload: {
		token: IFetchApiProps['token']
		projectId: number
		invoiceId: number
	}
) => {
	if (!payload.token || !payload.invoiceId) return

	const url = `/project/${payload.projectId}/invoice/${payload.invoiceId}/download`

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url,
		pendingKey,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetInvoiceInfo = async (
	payload: {
		token: IFetchApiProps['token']
		projectId: number | string
		invoiceId: number | string
	}
) => {
	if (!payload.token && !payload.projectId && !payload.invoiceId) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${payload.projectId}/invoice/${payload.invoiceId}`,
		payload: {
			id: payload.projectId,
			invoice_id: payload.invoiceId
		},
		pendingKey,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiPostInvoiceGenerate = async (
	payload: {
		data: components['schemas']['GenerateInvoice']
		projectId: number
		token: string
	}
) => {
	if (!payload.token && !payload.data) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${payload.projectId}/invoice/generate`,
		payload: payload.data,
		pendingKey,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiPutInvoiceRegenerate = async (
	payload: {
		data: components['schemas']['GenerateInvoice']
		projectId: number | string
		invoiceId: number | string
		token: string
	}
) => {
	if (!payload.token && !payload.data) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Put,
		url: `/project/${payload.projectId}/invoice/${payload.invoiceId}/re-generate`,
		payload: payload.data,
		pendingKey,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiPostInvoiceStatus = async (
	{
		invoiceId,
		projectId,
		token,
		status
	}: IFetchApiSetInvoiseStatus
) => {
	if (!invoiceId || !token) return

	const url = `/project/${projectId}/invoice/${invoiceId}/set-status`

	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		url,
		pendingKey,
		token,
		payload: {
			status
		}
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetInvoiceNotifyAll = async (
	{
		projectId,
		invoiceId,
		token
	}: {
		projectId: number | string
		invoiceId: number | string
		token: string
	}
) => {
	if (!token && !invoiceId && !projectId) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${projectId}/invoice/${invoiceId}/notify-all`,
		pendingKey,
		token
	})

	if (response?.data) {
		return response.data
	}
}

export interface IFetchApiGetServices {
	token: IFetchApiProps['token']
	params: {
		return_all: boolean
	}
}

export const fetchApiGetServices = async (
	payload: IFetchApiGetServices
) => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/project/service/list',
		pendingKey: STATE_STORE_KEYS.Services,
		token: payload.token,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiCancelInvoice = async ({
	token,
	invoiceId,
	projectId,
	payload
}: IFetchApiCancelInvoice) => {
	if (!token) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/invoice/${invoiceId}/cancel`,
		token,
		payload,
		pendingKey
	})

	if (response?.data) {
		return response.data
	}
}
