import { defineStore } from 'pinia'

import { userApi } from '~/quality-management/api/user-api/userApi'
import { useLoaderStore } from '~/quality-management/stores/loaderStore/useLoaderStore'

import type { IUserStore } from './userStoreTypes'

export const useUserStore = defineStore('qmUserStore', {
	state: (): IUserStore => ({
		user: null
	}),
	actions: {
		async getUserData () {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()
			try {
				const res = await userApi.getUserData()
				if (res && res.data) {
					this.user = res.data
				}
			} catch (error) {
				console.log(error)
			} finally {
				loaderStore.toggleIsLoading()
			}
		}
	}
})
